import { getOidcClient } from '@/auth/oidc-client'
import router from '@/router/index'
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from '@axios'
/**
 * OpenID Connect flow using oidc-client-js library(@see: https://github.com/IdentityModel/oidc-client-js)
 * @author Martin Besozzi <mbesozzi@identicum.com>
 */

const defaultState = {
  user: {},
  isOidcEventsHandled: false,
}

let oidcClient = getOidcClient()

const actions = {
  signin: () => {
    oidcClient.signinRedirect()
  },
  signinRedirectCallback: context => {
    context.commit('TOKEN_REQUEST_PENDING')
    oidcClient.signinRedirectCallback()
      .then(response => {
        context.commit('TOKEN_REQUEST_SUCCESS')
        context.dispatch('setAuthenticationSuccess', response)

        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            const messaging = getMessaging();
            //  console.log('Firebase vapidKey : ' + process.env.VUE_APP_FIREBASE_VAPIDKEY);
            getToken(messaging, { vapidKey: "BK3jqRLI6L7yU6aC9Lv2drpz8_q-0rozZSV6g929i5Xp01vv3RVYz4A8fR5Ff8SW6cTQYh-d3gS_JLh55HIVG7M" }).then((currentToken) => {
              if (currentToken) {
                console.log('Firebase Token : ' + currentToken);
                const data = {
                  ApplicationId: 2,
                  FirebaseToken: currentToken
                }

                return new Promise((resolve, reject) => {
                  axios
                    .post('nh/Notification/CreateNotificationToken', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
                })
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
            });
          }
        });

        const userData = response.profile
        localStorage.setItem("userData", JSON.stringify(userData));
        store.dispatch("pamis/GetNavOrgTree");
        router.push('/')

      })
      .catch(error => {
        context.commit('TOKEN_REQUEST_FAILURE', error)
      })
  },
  setAuthenticationSuccess: (context, user) => {
    useJwt.setToken(user.access_token);
    // Check if library is suscribed to OIDC library events
    if (!context.state.isOidcEventsHandled) {
      oidcClient.events.addAccessTokenExpired(() => {
        // TODO: Handliing token expiration
      })
      context.commit('OIDC_LIBRARY_EVENTS_HANDLED')
    }
    context.commit('AUTHENTICATION_SUCCESS', user)
  },
  signOut: context => {
    context.commit('LOGOUT_REQUEST_PENDING')
    oidcClient.clearStaleState();
    oidcClient.signoutRedirect()
      .then(() => {
        context.commit('LOGOUT_REQUEST_SUCCESS')
      })
      .catch(error => {
        context.commit('LOGOUT_REQUEST_FAILURE', error)
      })
  },
  checkAccess: (context, requiresAuth) => new Promise(resolve => {
    /*
    var asd = {"sub":"2fe1c0c8-42b5-43c3-b970-92b7d5a3e3e0","name":"Demo User","email":"Demo user","oi_au_id":"1e5e72de-dae1-4673-8a28-5898b8170942","azp":"vueclient_dev","at_hash":"VCeOCJWXUEoRhO7nxyC5cg","oi_tkn_id":"da1bccc0-9a4e-4c13-9ccb-eee3a9f0c315","aud":"vueclient_dev","exp":1721908021,"iss":"https://apitest.niso.dev/pamis/identity-v2","iat":1721906821,"email_verified":false};
    localStorage.setItem("userData", JSON.stringify(asd))

    useJwt.setToken("eyJhbGciOiJSUzI1NiIsImtpZCI6IjRDMjkxMEZFQ0Y0RDA2RDc3QzExMUY5NzNGRjVDRjM4QjE0MjQyNTUiLCJ4NXQiOiJUQ2tRX3M5TkJ0ZDhFUi1YUF9YUE9MRkNRbFUiLCJ0eXAiOiJhdCtqd3QifQ.eyJzdWIiOiIyZmUxYzBjOC00MmI1LTQzYzMtYjk3MC05MmI3ZDVhM2UzZTAiLCJuYW1lIjoibGltYV9vcGVyYXRvciIsImVtYWlsIjoibGltYV9vcGVyYXRvciIsInJvbGUiOiJPUEVSQVRPUiIsIm9pX3Byc3QiOiJ2dWVjbGllbnQiLCJpc3MiOiJodHRwczovL2FwaXRlc3Qubmlzby5kZXYvcGFtaXMvaWRlbnRpdHktdjIiLCJvaV9hdV9pZCI6ImExYjdkMjU5LThlZDYtNGI1Ny05M2U3LTcxM2Y4MGVlOTUwZCIsImNsaWVudF9pZCI6InZ1ZWNsaWVudCIsIm9pX3Rrbl9pZCI6IjU2ZDFjNmZhLTIyMDItNGEwNy04ZWU0LTFkNzY4ZDlhNmQ0OCIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwgb2ZmbGluZV9hY2Nlc3MiLCJqdGkiOiI3YWUwMmFhMi1mNmY2LTQ5MTUtOGM5Zi0xN2Q3YmI3NTJmNWYiLCJleHAiOjE3MjE5MDA5MzUsImlhdCI6MTcyMTg5NzMzNX0.T1C8Qp0Se9vH3Wn9d1Q7h7jYdaWpKlRYhUVrwuhG0Dz-gINu8nLC_9M_NPwpGGJkA0Uw41M6wJVYiAbHbxmTDu2EGLobEGqEtA100ZX495OJBHiaW6L2h6HGb__OLxLKKk-Gux4IxA0_GRwCTqNQeaeyKtqa0G4OwEuKSNsXDUmc_c_dwD1723_p8sKAnYzuk7JHP9c_Lr8_gPGzYN8Scfp2a-AJc6AoFxi4iFSdTTiwrzv76YI6uUR7WMNSWgy8LHhWfJQ2kzq2zhk-PhiklRS4l0fWaK-MPUv18-rEoHPGH8i5d67FUD2S2ueTXZc3QYBRiK1bs6EJiCRA_vK-ew");
    // useJwt.setRefreshToken(response.data.refreshToken.code)
    store.dispatch("pamis/GetNavOrgTree");
    // context.dispatch('setAuthenticationSuccess', user)
    resolve('OK')
*/


    oidcClient = getOidcClient()
    if (!requiresAuth) return resolve('OK')
    // Check if we have a token in Session Storage
    const getUserPromise = new Promise(resolve => {
      oidcClient.getUser().then(user => {
        // debugger;
        resolve(user)
      }).catch(() => {
        resolve(null)
      })
    })
    let statusCode = 'UNAUTHORIZED'
    getUserPromise.then(user => {
      // Check if we have token information and if token is not expired
      if (user && !user.expired) {
        // debugger;
        statusCode = 'OK'
        context.dispatch('setAuthenticationSuccess', user)
      }
      resolve(statusCode)
    })
  })
  // })
  ,
}

const mutations = {
  TOKEN_REQUEST_PENDING: state => {
    state.user = { loading: true }
  },
  TOKEN_REQUEST_SUCCESS: state => {
    state.user = { loading: false }
  },
  TOKEN_REQUEST_FAILURE: (state, error) => {
    state.user = { error }
  },
  LOGOUT_REQUEST_PENDING: state => {
    state.user = { loading: true }
  },
  LOGOUT_REQUEST_SUCCESS: state => {
    state.user = {}
  },
  LOGOUT_REQUEST_FAILURE: (state, error) => {
    state.user = { error }
  },
  AUTHENTICATION_SUCCESS: (state, user) => {
    state.user = user
  },
  OIDC_LIBRARY_EVENTS_HANDLED: state => {
    state.isOidcEventsHandled = true
  },
}

const getters = {
  accessToken(state) {
    return state.user.access_token
  },
  isLoading(state) {
    return (state.user != null && state.user.isLoading)
  },
  tokenResponse(state) {
    return (state.user) ? state.user : {}
  },
  userProfile(state) {
    return (state.user
      && state.user.profile != null) ? state.user.profile : {}
  },
  userIsAuthenticated(state) {
    return (state.user
      && state.user.id_token != null)
  },
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}
