import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import i18n from '@/libs/i18n'

Vue.use(VueRouter);

import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/pamis/home/home-board/BoardHome.vue"),
      meta: {
        pageTitle: "Global",
        breadcrumb: [
          {
            href: "/",
            text: "Global",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/home",
      name: "PamisHome",
      component: () => import("@/views/pamis/home/home-board/BoardHome.vue"),
      meta: {
        pageTitle: "Global",
        breadcrumb: [
          {
            text: "Global",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/country/:name/id=:id/tab=:tab",
      name: "PamisCountry",
      component: () =>
        import("@/views/pamis/country/country-board/BoardCountry.vue"),
      meta: {
        pageTitle: i18n.t('Country'),
        requiresAuth: true,
      },
      children: [],
    },
    {
      path: "/workcenter/:name/id=:id",
      name: "PamisWorkcenter",
      component: () =>
        import("@/views/pamis/workcenter/workcenter-board/BoardWorkcenter.vue"),
      meta: {
        pageTitle: i18n.t('Workcenter'),
        requiresAuth: true,
      },
    },

    {
      path: "/breakdown",
      name: "PamisBreakdown",
      component: () =>
        import(
          "@/views/pamis/breakdown/breakdown-pamis-list/BreakdownPamisList.vue"
        ),
      meta: {
        pageTitle: "Downtimes",
        breadcrumb: [
          {
            text: "Downtimes",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/PamisJobs",
      name: "PamisJobs",
      component: () =>
        import(
          "@/views/pamis/workorder/job-list/JobList.vue"
        ),
      meta: {
        pageTitle: i18n.t('Task List'),
        breadcrumb: [
          {
            text: i18n.t('Task List'),
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    /*
    {
      path: '/PamisOperatorJobs/addJobEffort',
      name: 'AddJobEffort',
      component: () => import('@/views/pamis/workorder/operator-list/AddJobEffort.vue'),
      meta: {
        pageTitle: 'Operator Add Effort',
        breadcrumb: [
          {
            text: 'Operator Add Effort',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    */
    {
      path: '/PamisOperatorJobs',
      name: 'PamisOperatorJobs',
      component: () => import(
        '@/views/pamis/workorder/operator-list/Calendar.vue'
      ),
      meta: {
        pageTitle: i18n.t('Task Efforts'),
        breadcrumb: [
          {
            text: i18n.t('Task Efforts'),
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/PamisJobs",
      name: "PamisJobs",
      component: () =>
        import(
          "@/views/pamis/workorder/job-list/JobList.vue"
        ),
      meta: {
        pageTitle: i18n.t('Task List'),
        breadcrumb: [
          {
            text: i18n.t('Task List'),
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/PamisOperatorJobs/addJobEffort',
      name: 'AddJobEffort',
      component: () => import('@/views/pamis/workorder/operator-list/AddJobEffort.vue'),
      meta: {
        pageTitle: 'Operator Add Effort',
        breadcrumb: [
          {
            text: 'Operator Add Effort',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/PamisOperatorJobs',
      name: 'PamisOperatorJobs',
      component: () => import(
        '@/views/pamis/workorder/operator-list/OperatorJobList.vue'
      ),
      meta: {
        pageTitle: 'Operator Task List',
        breadcrumb: [
          {
            text: 'Operator Task List',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    /*
    {
      path: "/breakdown/wcId=:wcId",
      name: "PamisBreakdown",
      component: () =>
        import(
          "@/views/pamis/breakdown/breakdown-pamis-list/BreakdownPamisList.vue"
        ),
      meta: {
        pageTitle: "Downtimes",
        breadcrumb: [
          {
            text: "Downtimes",
            active: true,
          },
        ],
      },
    },
    */
    {
      path: "/PredictiveMaintenance",
      name: "PredictiveMaintenance",
      component: () =>
        import(
          "@/views/pamis/maintenance/PredictiveMaintenance/PredictiveMaintenance.vue"
        ),
      meta: {
        pageTitle: i18n.t('Predictive Maintenance'),
        breadcrumb: [
          {
            text: i18n.t('Predictive Maintenance'),
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: "/ProductionReports",
      name: "ProductionReports",
      component: () =>
        import(
          "@/views/pamis/production_reports/PruductionReports.vue"
        ),
      meta: {
        pageTitle: i18n.t('Production Reports'),
        breadcrumb: [
          {
            text: i18n.t('Production Reports'),
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: "/PredictiveMaintenance/Simulation",
      name: "PredictiveMaintenanceSettings",
      component: () =>
        import(
          "@/views/pamis/maintenance/PredictiveMaintenanceSettings/PredictiveMaintenanceSettings.vue"
        ),
      meta: {
        pageTitle: i18n.t('Predictive Maintenance'),
        breadcrumb: [
          {
            text: i18n.t('Simulation'),
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    /*
    {
      path: "/test",
      name: "test",
      component: () =>
        import(
          "@/views/pamis/predictiveMaintenance/test.vue"
        ),
      meta: {
        pageTitle: "tes Maintenance ",
        breadcrumb: [
          {
            text: "test Maintenance",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    */

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginServer.vue"),
      meta: {
        layout: "full",
      },
    },
    /*
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/LoginServer.vue"),
          meta: {
            layout: "full",
          },
        },
        /*
        {
          path: '/callback',
          name: 'Callback',
          component: () => import('@/views/Callback.vue'),
        },*/
    {
      path: '/callback',
      name: 'Callback',
      component: () => import('@/views/Callback.vue'),
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/miscellaneous/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

/*
router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn();
  //   console.log("forward");
  // if (to.fullPath.substring(0, 2) === "/#") {
  //   const path = to.fullPath.substring(2);
  //   next(path);
  //   return;
  // }
  next();
  //   var forweardToLogin= localStorage.getItem('needstogoLogin');
  //  console.log("forweardToLogin "+forweardToLogin);

  //   if (forweardToLogin === "true")
  //   {
  //     console.log("in forward logged in ");
  //     if (  to.name !== 'login') {
  //       localStorage.setItem('needstogoLogin',false)
  //       console.log("in change root to logged in ");
  //       return next({ name: 'login' })
  //     }

  if (to.name != "login" && !window.localStorage.getItem("redirectURL")) {
    window.localStorage.setItem("redirectURL", to.fullPath);
  }

  if (
    isLoggedIn == null &&
    (to.name == "login" || to.name == "choosecompany")
  ) {
    next();
  } else if (isLoggedIn == null && to.name != "login") {
    return next({ name: "login" });
  } else if (
    isLoggedIn == false &&
    (to.name !== "login" || to.name !== "choosecompany")
  ) {
    return next({ name: "login" });
  } else {
    return next();
  }

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});
*/
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    store.dispatch('auth/checkAccess', requiresAuth).then(statusCode => {
      if (statusCode === 'OK') {
        next()
      } else {
        store.dispatch("auth/signin");
        //return next({ name: 'login' })
      }
    })
  } else {
    next()
    // return next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
