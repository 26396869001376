import axios from "@axios";
import store from "@/store";
/**
 * Service to call HTTP request via Axios
 */
// const baseURL= `${process.env.VUE_APP_API_URL}/pamis/pda/`
// const baseURL= 'https://apitest.niso.dev/pamis/pda/'
//const baseURL= 'https://localhost:44308/pamis/pda/'
/*
const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.eldor.com.tr/pamis/pda/"
    : "https://apitest.niso.dev/pamis/pda/";
*/
const baseURL = `${process.env.VUE_APP_API_URL}/pamis/pda/`


const ApiService = {
  controller: new AbortController(),
  abortRequests() {
    if (this.controller) this.controller.abort();
    this.controller = new AbortController();
  },

  query(resource, params) {
    const uri = baseURL + resource;
    store.dispatch("app/ADD_LOADING_STATE");
    return axios
      .get(uri, { params: params, signal: this.controller.signal })
      .catch((error) => {
        // console.log(error);
        throw new Error(error.response.data.message);
      })
      .finally(() => {
        store.dispatch("app/REMOVE_LOADING_STATE");
      });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    store.dispatch("app/ADD_LOADING_STATE");
    const uri = baseURL + `${resource}/${slug}`;
    return axios
      .get(uri, { signal: this.controller.signal })
      .catch((error) => {
        throw new Error(error.response.data.message);
      })
      .finally(() => {
        store.dispatch("app/REMOVE_LOADING_STATE");
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(baseURL + `${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return axios.put(baseURL + `${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return axios.put(baseURL + `${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return axios.delete(resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
