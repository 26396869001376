import { $themeBreakpoints } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    activeUnit: 0,
    activeLecture: 0,
    loadingStateCount: 0,
    breadCrumb: [],
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    currentUnit: (state) => {
      return state.activeUnit;
    },
    currentLecture: (state) => {
      return state.activeLecture;
    },
    loadingStateCount: (state) => {
      return state.loadingStateCount;
    },
    extensionalBreadCrumb: (state) => {
      return state.breadCrumb;
    },
  },
  actions: {
    ADD_LOADING_STATE(ctx) {
      ctx.commit("UPDATE_LOADING_STATE", 1);
    },
    REMOVE_LOADING_STATE(ctx) {
      ctx.commit("UPDATE_LOADING_STATE", -1);
    },
    ADD_BREAD_CRUMB(ctx, param) {
      ctx.commit("ADD_BREAD_CRUMB", param);
    },
    CLEAR_BREAD_CRUMB(ctx, param) {
      ctx.commit("CLEAR_BREAD_CRUMB", param);
    },
  },
  mutations: {
    ADD_BREAD_CRUMB(state, val) {
      state.breadCrumb = val;
    },
    CLEAR_BREAD_CRUMB(state) {
      state.breadCrumb = [];
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    SETACTIVEUNIT(state, val) {
      state.activeUnit = val;
    },
    SETACTIVELECTURE(state, val) {
      state.activeLecture = val;
    },
    UPDATE_LOADING_STATE(state, val) {
      state.loadingStateCount += val;
    },
  },
};
