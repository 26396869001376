import useJwt from "@/auth/jwt/useJwt";
import ApiService from "@/services/pamisDashboardApi.service";
import mockdatagetcountrieswithworkcenters from "@/mockdata/getcountrieswithworkcenters.json"
import mockdatagetsitesbycountryid from "@/mockdata/getsitesbycountryid.json"
import mockdatagetcountries from "@/mockdata/getcountries.json"
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {
    countries: [],
    sites: [],
    sitesByCountryId: [],
    workcentersBySiteId: [],
    navOrgTree: [],
    dateFilter: null,
    openNavigationGroups: [],
  },
  getters: {
    GetOpenNavigationGroups(state) {
      return state.openNavigationGroups;
    },
    GetCountries(state) {
      return state.countries;
    },
    GetSites(state) {
      return state.sites;
    },
    GetSitesByCountryId(state) {
      return state.sitesByCountryId;
    },
    GetWorkcentersBySiteId(state) {
      return state.workcentersBySiteId;
    },
    GetNavOrgTree(state) {
      return state.navOrgTree;
    },
    GetDateFilter(state) {
      return state.dateFilter;
    },
  },
  actions: {
    AddOpenNavigationGroup(ctx, item) {
      ctx.commit("AddOpenNavigationGroup", item);
    },
    RemoveOpenNavigationGroup(ctx, item) {
      ctx.commit("RemoveOpenNavigationGroup", item);
    },
    GetDateFilter(ctx, data) {
      ctx.commit("SetDateFilter", data);
    },
    GetNavOrgTree(context) {
      let data = mockdatagetcountrieswithworkcenters;
      context.commit("SetNavOrgTree", data);
        // return new Promise((resolve, reject) => {
        // ApiService.get("Organization", "GetCountriesWithWorkCenters")
        //   .then((response) => {
        //     context.commit("SetNavOrgTree", response.data);
        //     resolve(response.data);
        //   })
        //   .catch((error) => {
        //     reject(error);
        //   });
        // });
    },
    GetCountries(context) {
      let data = mockdatagetcountries;
      context.commit("SetCountries", data);
      // return new Promise((resolve, reject) => {
      //   ApiService.get("Organization", "GetCountries")
      //     .then((response) => {
      //       context.commit("SetCountries", response.data);
      //       resolve(response.data);
      //     })
      //     .catch((error) => {
      //       reject(error);
      //     });
      // });
    },
    GetSites(context) {
      return new Promise((resolve, reject) => {
        ApiService.get("Organization", "GetSites")
          .then((response) => {
            context.commit("SetSites", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetSitesByCountryId(context, countryId) {
      
      let data = mockdatagetsitesbycountryid;
      data.datasource.find(x=>x.id == 100).name = i18n.t('ABS Plant')
      data.datasource.find(x=>x.id == 101).name = i18n.t('IC Plant')
      context.commit("SetSitesByCountryId", data);
      // return new Promise((resolve, reject) => {
      //   ApiService.query("Organization/GetSitesByCountryId", {
      //     countryId: countryId,
      //   })
      //     .then((response) => {
      //       context.commit("SetSitesByCountryId", response.data);
      //       resolve(response.data);
      //     })
      //     .catch((error) => {
      //       reject(error);
      //     });
      // });
    },
    GetWorkcentersBySiteId(context, siteId) {
      return new Promise((resolve, reject) => {
        ApiService.query("Organization/GetWorkcentersBySiteId", {
          siteId: siteId,
        })
          .then((response) => {
            context.commit("SetWorkcentersBySiteId", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    AddOpenNavigationGroup(state, item) {
      let foundSameLevelItem = state.openNavigationGroups.findIndex(
        (x) => x.level == item.level
      );
      if (foundSameLevelItem != -1)
        state.openNavigationGroups[foundSameLevelItem] = item;
      else state.openNavigationGroups.push(item);
    },
    SetNavOrgTree(state, orgTreeList) {
      window.localStorage.setItem(
        "NavbarOrganization",
        JSON.stringify(orgTreeList)
      );
      state.navOrgTree = orgTreeList;
    },
    SetCountries(state, countries) {
      state.countries = countries;
    },
    SetSites(state, sites) {
      state.sites = sites;
    },
    SetSitesByCountryId(state, sites) {
      state.sitesByCountryId = sites.datasource;
    },
    SetWorkcentersBySiteId(state, workcenters) {
      state.workcentersBySiteId = workcenters.datasource;
    },
    SetDateFilter(state, data) {
      state.dateFilter = data;
    },
  },
};
